import React, { createContext, useReducer, useContext } from 'react';

// Estado inicial
const initialState = {
  products: [
    {
      id: "1",
      name: "Chaleco",
      fabrics: [
        {
          id: "chaleco_fabric_0",
          name: "Lycra",
          finalName: "Chaleco ciclista (Lycra)",
          description: "Chaleco ciclista con tela Lycra microperforada 100% sublimado. Incluye: cierre ykk, antideslizante trasero, bolsillo trasero.",
          basePrice: {
            "10+": 17000,
            "5-9": 21000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            agregado_refranctario: 2000,
            cuello_mao: 0,
            cuello_alto: 0
          },
          removeItem: true
        },
        {
          id: "chaleco_fabric_1",
          name: "Ristop mini",
          finalName: "Chaleco rompe viento (Ristop mini)",
          description: "Chaleco rompe viento, en tela ristop mini 100% sublimado. Incluye: cierre ykk, bolsillos frontales.",
          basePrice: {
            "10+": 26000,
            "5-9": 31000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            capucha: 0,
            cierre_trasero: 0,
            bolsillo_en_el_frente: 0,
            bolsillo_en_el_frente_con_cierre: 1500
          },
          removeItem: true
        }
      ]
    },
    {
      id: "2",
      name: "Remera manga corta",
      fabrics: [
        {
          id: "remera_manga_corta_fabric_0",
          name: "Lycra",
          finalName: "Remera manga corta (Lycra)",
          description: "Remeras cortas 100% sublimadas. Proceso drifit y costura reforzada.",
          basePrice: {
            "10+": 13000,
            "5-9": 15000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            cuello_en_V: 0,
            cuello_redondo: 0,
            vinilo_dtf_pequeno: 3000,
            vinilo_dtf_grande: 4000
          },
          removeItem: true
        },
        {
          id: "remera_manga_corta_fabric_1",
          name: "Poc Premium",
          finalName: "Remera manga corta (Poc Premium)",
          description: "Remeras cortas 100% sublimadas. Proceso drifit y costura reforzada.",
          basePrice: {
            "10+": 13000,
            "5-9": 15000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            cuello_en_V: 0,
            cuello_redondo: 0,
            vinilo_dtf_pequeno: 3000,
            vinilo_dtf_grande: 4000
          },
          removeItem: true
        },
        {
          id: "remera_manga_corta_fabric_2",
          name: "Jersey de algodón",
          finalName: "Remera manga corta (Jersey de algodón)",
          description: "Remeras DTF o estampadas. Incluye costura reforzada y logo chico en el frente y logo grande en la espalda.",
          basePrice: {
            "10+": 13000,
            "5-9": 15000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            cuello_en_V: 0,
            cuello_redondo: 0,
            vinilo_dtf_pequeno: 3000,
            vinilo_dtf_grande: 4000
          },
          removeItem: true
        }
      ]
    },
    {
      id: "3",
      name: "Jersey de ciclismo manga corta",
      fabrics: [
        {
          id: "jersey_de_ciclismo_manga_corta_fabric_0",
          name: "Lycra",
          finalName: "Jersey de ciclismo manga corta (Lycra)",
          description: "Jersey de ciclismo manga corta con tela Lycra microperforada 100% sublimado. Incluye antidezlizante y cierre ikk, además de elásticos en el sector inferior de la prenda y en bolsillos.",
          basePrice: {
            "10+": 22000,
            "5-9": 25000
          },
          sizes: ["XS", "S", "M", "L", "XL", "XXL"],
          extras: {
            refractario: 2000
          },
          removeItem: true
        }
      ]
    },
    {
      id: "4",
      name: "Campera",
      fabrics: [
        {
          id: "campera_fabric_0",
          name: "Ristop mini",
          finalName: "Campera (Ristop mini)",
          description: "Campera rompe viento con tela ristop mini 100% sublimada. Incluye cierre largo ykk, además de un elástico interno ajustable en la cintura y bolsillo trasero con cierre ykk.",
          basePrice: {
            "10+": 33000,
            "5-9": 36000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            capucha: 0,
            cierre_trasero: 0,
            bolsillo_frente: 0,
            bolsillo_frente_cierre: 1500,
            vinilo_dtf_pequeno: 2000,
            vinilo_dtf_grande: 4000,
            detalle_refractario: 3000
          },
          removeItem: true
        }
      ]
    },
    {
      id: "5",
      name: "Jersey ciclismo manga larga",
      fabrics: [
        {
          id: "jersey_de_ciclismo_manga_larga_fabric_0",
          name: "Lycra",
          finalName: "Jersey ciclismo manga larga (Lycra)",
          description: "Jersey de ciclismo manga larga con tela Lycra calada 100% sublimado. Incluye antidezlizante y cierre ikk, además de elásticos en el sector inferior de la prenda y en bolsillos.",
          basePrice: {
            "10+": 24000,
            "5-9": 27000
          },
          sizes: ["XS", "S", "M", "L", "XL", "XXL"],
          extras: {
            refractario: 2000
          },
          removeItem: true
        }
      ]
    },
    {
      id: "6",
      name: "Remera manga larga",
      fabrics: [
        {
          id: "remera_manga_larga_fabric_0",
          name: "Lycra",
          finalName: "Remera manga larga (Lycra)",
          description: "Remera manga larga con tela lycra microperforada 100% polliester y 100% sublimado. Incluye costura reforzada y proceso drifit.",
          basePrice: {
            "10+": 15000,
            "5-9": 18000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        },
        {
          id: "remera_manga_larga_fabric_1",
          name: "Poc Premium",
          finalName: "Remera manga larga (Poc Premium)",
          description: "Remera manga larga con tela poc premium y 100% sublimado. Incluye costura reforzada y proceso drifit.",
          basePrice: {
            "10+": 15000,
            "5-9": 18000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        }
      ]
    },
    {
      id: "7",
      name: "Calza ciclismo corta",
      fabrics: [
        {
          id: "calza_ciclismo_corta_fabric_0",
          name: "Lycra",
          finalName: "Calza ciclismo corta (Lycra)",
          description: "Calza ciclista con tela lycra con badana coolmax para mayor comodidad.",
          basePrice: {
            "10+": 18000,
            "5-9": 21000
          },
          sizes: ["XS", "S", "M", "L", "XL", "XXL"],
          extras: {
            refractario: 2000
          },
          removeItem: true
        }
      ]
    },
    {
      id: "8",
      name: "Calza ciclismo larga",
      fabrics: [
        {
          id: "calza_ciclismo_larga_fabric_0",
          name: "Lycra",
          finalName: "Calza ciclismo larga (Lycra)",
          description: "Calza ciclista larga con tela lycra y badana coolmax. Incluye antidezlizante en el sector inferior.",
          basePrice: {
            "10+": 24000,
            "5-9": 27000
          },
          sizes: ["XS", "S", "M", "L", "XL", "XXL"],
          extras: {
            refractario: 2000
          },
          removeItem: true
        }
      ]
    },
    {
      id: "9",
      name: "Conjunto Deportivo: short y remera",
      fabrics: [
        {
          id: "conjunto_deportivo_fabric_0",
          name: "Lycra",
          finalName: "Conjunto deportivo (Lycra)",
          description: "Conjunto deportivo de short y remera con costura reforzada y 100% sublimado.",
          basePrice: {
            "10+": 16000,
            "5-9": 18500
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            refractario: 2000
          },
          removeItem: true
        },
        {
          id: "conjunto_deportivo_fabric_1",
          name: "Poc Premium",
          finalName: "Conjunto deportivo (Lycra)",
          description: "Conjunto deportivo de short y remera con costura reforzada y 100% sublimado.",
          basePrice: {
            "10+": 16000,
            "5-9": 18500
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        }
      ]
    },
    {
      id: "10",
      name: "Conjunto Deportivo: campera y pantalón",
      fabrics: [
        {
          id: "conjunto_deportivo_fabric_0",
          name: "Quetem",
          finalName: "Conjunto deportivo (Quetem)",
          description: "Conjunto deportivo de campera y pantalón con costura reforzada y 100% sublimado.",
          basePrice: {
            "10+": 60000,
            "5-9": 65000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {
            refractario: 2000
          },
          removeItem: true
        },
        {
          id: "conjunto_deportivo_fabric_1",
          name: "Frizado",
          finalName: "Conjunto deportivo (Frizado)",
          description: "Conjunto deportivo de campera y pantalón con costura reforzada y 100% sublimado.",
          basePrice: {
            "10+": 60000,
            "5-9": 65000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        }
      ]
    },
    {
      id: "11",
      name: "Short deportivo",
      fabrics: [
        {
          id: "short_deportivo_fabric_0",
          name: "Lycra",
          finalName: "Short deportivo (Lycra)",
          description: "Short deportivo con tela lycra microperforada 100% polliester y 100% sublimado. Incluye costura reforzada y proceso drifit.",
          basePrice: {
            "10+": 7500,
            "5-9": 9500
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        },
        {
          id: "short_deportivo_fabric_1",
          name: "Poc Premium",
          finalName: "Short deportivo (Poc Premium)",
          description: "Short deportivo con tela poc premium y 100% sublimado. Incluye costura reforzada y proceso drifit.",
          basePrice: {
            "10+": 7500,
            "5-9": 9500
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        }
      ]
    },
    {
      id: "12",
      name: "Musculosas",
      fabrics: [
        {
          id: "musculosa_fabric_0",
          name: "Lycra",
          finalName: "Musculosa (Lycra)",
          description: "Musculosa con tela lycra microperforada 100% polliester y 100% sublimado. Incluye costura reforzada y proceso drifit.",
          basePrice: {
            "10+": 12500,
            "5-9": 15000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        },
        {
          id: "musculosa_fabric_1",
          name: "Poc Premium",
          finalName: "Musculosa (Poc Premium)",
          description: "Musculosa con tela poc premium y 100% sublimado. Incluye costura reforzada y proceso drifit.",
          basePrice: {
            "10+": 12500,
            "5-9": 15000
          },
          sizes: [
            "06", "08", "10", "12", "14", "16", "38", "40", "42", "44",
            "46", "48", "50", "52", "54"
          ],
          extras: {},
          removeItem: true
        }
      ]
    },
  ],
  cart: [],
};

// Acciones
const ADD_PRODUCT = 'ADD_PRODUCT';
const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

// Acciones
const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
const UPDATE_CART_PRODUCT = 'UPDATE_CART_PRODUCT';
const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';


// Reducer
const orderReducer = (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return { ...state, products: [...state.products, action.payload] };
    case UPDATE_PRODUCT:
      return {
        ...state,
        products: state.products.map(product =>
          product.id === action.payload.id ? action.payload : product
        ),
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        products: state.products.filter(product => product.id !== action.payload.id),
      };
      case ADD_PRODUCT_TO_CART:
        const existingProduct = state.cart.find(product => product.id === action.payload.id);
        if (existingProduct) {
          return {
            ...state,
            cart: state.cart.map(product =>
              product.id === action.payload.id
                ? { ...product, quantity: product.quantity + action.payload.quantity, extras: action.payload.extras }
                : product
            ),
          };
        } else {
          return {
            ...state,
            cart: [...state.cart, action.payload],
          };
        }
  
      case UPDATE_CART_PRODUCT:
        return {
          ...state,
          cart: state.cart.map(product =>
            product.id === action.payload.id ? action.payload : product
          ),
        };

      case REMOVE_PRODUCT_FROM_CART:
        return {
          ...state,
          cart: state.cart.filter(product => product.id !== action.payload.id),
        };
    default:
      return state;
  }
};

// Crear contexto
const OrderContext = createContext();

// Proveedor de contexto
export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(orderReducer, initialState);

  const addProduct = product => dispatch({ type: ADD_PRODUCT, payload: product });
  const updateProduct = product => dispatch({ type: UPDATE_PRODUCT, payload: product });
  const removeProduct = productId => dispatch({ type: REMOVE_PRODUCT, payload: { id: productId } });

  const addProductToCart = product => dispatch({ type: ADD_PRODUCT_TO_CART, payload: product });
  const updateCartProduct = product => dispatch({ type: UPDATE_CART_PRODUCT, payload: product });
  const removeProductFromCart = productId => dispatch({ type: REMOVE_PRODUCT_FROM_CART, payload: { id: productId } });

  return (
    <OrderContext.Provider value={{
      ...state,
      addProduct,
      updateProduct,
      removeProduct,
      addProductToCart,
      updateCartProduct,
      removeProductFromCart,
    }}>
      {children}
    </OrderContext.Provider>
  );
};

// Hook para usar el contexto
export const useOrder = () => useContext(OrderContext);
