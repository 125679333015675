import React, { useState } from 'react';
import { ProductMenu } from './ProductMenu';
import { ProductConfigurator } from './ProductConfigurator';
import { SummaryCard } from '../molecules/SummaryCard';

export const QuoteCalculator = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <div className='d-flex flex-column align-items-center p-4' id="online-pricing">
      <h1 className='text-3xl mb-4'>Estima el valor de tu compra</h1>
      <ProductMenu onSelectProduct={setSelectedProduct} />

      <div className={`quote-calculator d-flex flex-column align-items-center w-100 mb-5 pb-5 ${selectedProduct ? 'flex-lg-row justify-content-lg-around' : 'justify-content-center'}`}>
        {selectedProduct && (
          <div className='mb-5 mb-lg-0 col-12 col-lg-5'>
            <ProductConfigurator product={selectedProduct} />
          </div>
        )}
        <div className={`${selectedProduct ? '' : 'd-flex flex-column align-items-center'}`}>
          <SummaryCard />
        </div>
      </div>

    </div>
  );
};
