import './style.css';
import { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import BsportLogo from '../../imgs/LOGO_Bsports_02.png';

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State for toggling the menu

  return (
    <div className="secondary-background-color z-50 fixed w-full py-2 px-10 flex items-center justify-between">
      <div>
        <img src={BsportLogo} alt="logo" style={{ width: '50px', height: 'auto' }} />
      </div>

      {/* Navigation links for larger screens */}
      <div className="hidden md:flex space-x-6 text-white font-semibold">
        <a href="#quienes-somos" className="hover:text-yellow-500 transition delay-50 duration-300 ease-in-out">Quienes Somos</a>
        {/* <a href="#productos" className="hover:text-yellow-500 transition delay-50 duration-300 ease-in-out">Productos</a> */}
        <a href="#contacto" className="hover:text-yellow-500 transition delay-50 duration-300 ease-in-out">Contacto</a>
      </div>

      {/* Burger menu for mobile screens */}
      <div className="md:hidden flex items-center">
        <FaBars className="text-white text-2xl cursor-pointer" onClick={() => setMenuOpen(!menuOpen)} />
      </div>

      {/* Dropdown menu for mobile screens */}
      {menuOpen && (
        <div className="absolute top-14 right-0 secondary-background-color w-full flex flex-col items-center md:hidden space-y-4 py-4 z-50">
          <a href="#quienes-somos" className="text-white font-semibold hover:text-indigo-600 transition delay-300 duration-300 ease-in-out" onClick={() => setMenuOpen(false)}>Quienes Somos</a>
          {/* <a href="#productos" className="text-white font-semibold hover:text-indigo-600 transition delay-300 duration-300 ease-in-out" onClick={() => setMenuOpen(false)}>Productos</a> */}
          <a href="#contacto" className="text-white font-semibold hover:text-indigo-600 transition delay-300 duration-300 ease-in-out" onClick={() => setMenuOpen(false)}>Contacto</a>
        </div>
      )}
    </div>
  );
};
