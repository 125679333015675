
export const CustomButton = (props) => {
  return (
    <>
      <a
        className="d-flex justify-content-center align-items-center primary-background-color italic uppercase py-4 px-6 rounded secondary-color text-sm font-bold w-full md:w-fit text-center"
        href={props.path}
        onClick={props.onClick}
      >{props.text}</a>
    </>
  );
};