import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { OrderProvider } from './context/context.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <OrderProvider>
      <App />
    </OrderProvider>
  </React.StrictMode>
);
