import React, { useState, useEffect } from 'react';
import { useOrder } from '../../context/context';
import "./style.css"

export const ProductConfigurator = ({ product }) => {
  const { cart, addProductToCart, updateCartProduct } = useOrder();
  const [quantity, setQuantity] = useState(0);
  const [size, setSize] = useState('');
  const [selectedExtras, setSelectedExtras] = useState({});
  const [notes, setNotes] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setQuantity(0);
    setSize('');
    setSelectedExtras({});
    setNotes('');
  }, [product]);

  useEffect(() => {
    const existingProduct = cart.find(item => item.id === product.id);
    if (existingProduct) {
      setQuantity(existingProduct.quantity);
      setSize(existingProduct.size);
      setSelectedExtras(existingProduct.extras || {});
      setNotes(existingProduct.notes);
      setIsUpdating(true);
    } else {
      setIsUpdating(false);
    }
  }, [product, cart]);

  const handleExtraChange = (extra) => {
    setSelectedExtras((prevExtras) => ({
      ...prevExtras,
      [extra]: !prevExtras[extra],
    }));
  };

  const calculatePrice = () => {
    const basePriceKey = quantity >= 10 ? '10+' : '5-9';
    let price = product.basePrice[basePriceKey];

    for (const extra in selectedExtras) {
      if (selectedExtras[extra] && product.extras[extra] > 0) {
        price += product.extras[extra];
      }
    }

    return price * quantity;
  };

  const generateUniqueId = () => {
    return 'id-' + Math.random().toString(36).substr(2, 9);
};

const handleAddOrUpdateProduct = () => {
    if (quantity === 0 || !size) {
        setErrorMessage('Cantidad y talle son requeridos.');
        return;
    }

    setErrorMessage('');

    const configuredProduct = {
        ...product,
        quantity,
        size,
        extras: selectedExtras,
        notes,
        totalPrice: calculatePrice(),
    };

    // Check for existing products by name
    const existingProducts = cart.filter(item => item.finalName === product.finalName);

    if (existingProducts.length === 0) {
        // No product with the same name, add as new product
        addProductToCart({ ...configuredProduct, id: generateUniqueId() });
    } else {
        // Check if any existing product matches size and extras
        const matchingProduct = existingProducts.find(item =>
            item.size === size &&
            JSON.stringify(item.extras) === JSON.stringify(selectedExtras)
        );

        if (matchingProduct) {
            // Update the existing product's quantity and price
            const updatedProduct = {
                ...matchingProduct,
                quantity: quantity,
                totalPrice: calculatePrice(),
            };
            updateCartProduct(updatedProduct);
        } else {
            // Size or extras do not match, add as new product with a unique ID
            addProductToCart({ ...configuredProduct, id: generateUniqueId() });
        }
    }

    // Reset form state
    setQuantity(0);
    setSize('');
    setSelectedExtras({});
    setNotes('');
};




  return (
    <div className="product-configurator">
      <h3 className='text-2xl mb-1'>{product.finalName}</h3>
      <p className='mb-4'>{product.description}</p>

      <div className='mb-4'>
        <label className='mr-3'><b>Cantidad:</b></label>
        <input
          style={{backgroundColor: "#efefef"}}
          type="number"
          min={0}
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
        />
      </div>

      <div>
        <label className='mr-3'><b>Talle:</b></label>
        <select value={size} onChange={(e) => setSize(e.target.value)}>
          <option value="" disabled>Seleccione un talle</option>
          {product.sizes.map((sizeOption) => (
            <option key={sizeOption} value={sizeOption}>
              {sizeOption}
            </option>
          ))}
        </select>
      </div>

      {JSON.stringify(product.extras) !== "{}" && (
        <>
          <h4 className='mt-4 mb-2'><b>Extras:</b></h4>
          {Object.keys(product.extras).map((extra) => (
            <div key={extra}>
              <label>
                <input
                  type="checkbox"
                  className='mr-2'
                  checked={selectedExtras[extra] || false}
                  onChange={() => handleExtraChange(extra)}
                />
                {extra.replace('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}
                ({product.extras[extra] === 0 ? ' sin cargo ' : `+$${product.extras[extra]}`})
              </label>
            </div>
          ))}
          <small>
            * En caso de bordados o vinilos se agrega el costo de matriz por única vez de $10.000 para todos los artículos.
          </small>
        </>
      )}

      <div className='d-flex flex-column my-4'>
        <label><b>Anotaciones:</b></label>
        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Escribe alguna anotación aquí"
          style={{ backgroundColor: "#efefef"}}
          className='mt-1 p-2'
        />
      </div>

      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

      <div className='d-flex justify-content-between align-items-center'>
        <h4><b>Precio Total: ${calculatePrice()}</b></h4>

        <button
          className='product-submit-btn py-3 px-4'
          onClick={handleAddOrUpdateProduct}
        >
          {isUpdating ? 'Actualizar producto' : 'Agregar al pedido'}
        </button>
      </div>
    </div>
  );
};
