import "./style.css";
import BsportsLogo from "../../imgs/LOGO_Bsports_02.png";
import { CustomButton } from "../atoms/CustomButton";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";

export const Footer = () => {
  return (
    <div className="secondary-background-color text-white py-4 px-10" id="contacto">
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="p-4 col-12 col-md-8 col-xl-10">
          <div className="col-12 col-md-3 mb-4 d-flex justify-content-center justify-content-md-start">
            <img src={BsportsLogo} alt="logo" style={{ width: '100px', height: 'auto' }} />
          </div>
          <div className="text-slate-400">
            <p className="mb-3">
              <b className="text-white mr-2">Ubicación: </b>Libertador 868 - Moreno. Provincia de Buenos aires. Argentina. CP 1744
            </p>
            <p><b className="text-white mr-2">Horarios: </b>lunes a viernes de 9:00 - 19:00 HS</p>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-around col-12 col-md-4 col-xl-2">
          <CustomButton
            text={<FaWhatsapp className="phoneIcon" />}
            path="https://api.whatsapp.com/send/?phone=541165807752&text&type=phone_number&app_absent=0"
            className="mb-3 mb-md-0 mx-md-2"
          />
          <div className="my-2"></div>
          <CustomButton
            text={<FaInstagram className="phoneIcon" />}
            path="https://www.instagram.com/indumentaria.bsports/"
            className="mx-md-2"
          />
        </div>
      </div>
      <div className="pt-8 mt-4 text-center border-solid border-slate-800 border-t-2">
        <p className="text-slate-400 mb-8 text-xs">© 2024 All Rights Reserved | BSPORTS. Terms & conditions.</p>
      </div>
    </div>
  );
};
