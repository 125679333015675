import emailjs from 'emailjs-com'
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import "./style.css";
import { SubTitle } from "../atoms/SubTitle";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useOrder } from '../../context/context';


export const Modal = ({ open = false, closeModal }) => {
  // Form inputs
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [provincia, setProvincia] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [dni, setDni] = useState('');

  const { cart } = useOrder()

  // Function to create a message from cart items
  const generateMessage = () => {
    return cart.map((item, index) => {
      const extrasList = Object.entries(item.extras)
        .filter(([key, value]) => value)
        .map(([key]) => key)
        .join(", ");

      return `
        Producto ${index + 1}: ${item.finalName}
        Talle: ${item.size}
        Cantidad: ${item.quantity}
        Notas: ${item.notes || 'Ninguna'}
        Extras: ${extrasList || 'Ninguno'}
      `;
    }).join("\n\n");
  };

  let templateParams = {
    name: name,
    phone: phone,
    email: email,
    address: address,
    provincia: provincia,
    localidad: localidad,
    codigoPostal: codigoPostal,
    dni: dni,
    total: cart.reduce((acc, item) => acc + item.totalPrice, 0),
    message: generateMessage()
  };

  const submitForm = (e) => {
    e.preventDefault()

    // Check if any of the required fields are empty
    if (!name || !phone || !email || !address || !provincia || !localidad || !codigoPostal || !dni) {
      toast.error("Por favor completa todos los campos antes de enviar el pedido.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if(!templateParams.message){
      toast.error("No has cotizado tu pedido. Por favor intenta agregar productos al carrito para que podamos contactarnos contigo lo antes posible.", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    emailjs.send('service_1uwsx5n', 'template_wfefskk', templateParams, 'lHsV-y4LDmWbl98qQ')
      .then(function(response) {
          toast.success("Gracias por enviarnos tu pedido. Pronto estaremos en contacto.", {
            position: toast.POSITION.TOP_RIGHT
          })
      }, function(error) {
          toast.error("Hubo un problema al enviar tu pedido. Por favor intenta más tarde o busca contactarnos por otro medio.", {
            position: toast.POSITION.TOP_RIGHT
          })
      });

    closeModal()
  }


  return (
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto mt-4">
            <div className="flex items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mt-5 w-full max-w-[750px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title>
                    <SubTitle text="Completa el formulario para enviarnos tu pedido" />
                  </Dialog.Title>
                  <div className="my-2">
                    <p className="text-base text-slate-500">
                      Haremos uso de la información que completes a continuación para ponernos en contacto con vos y hacerte llegar el pedido lo antes posible.
                    </p>
                  </div>
                  <form className="grid grid-cols-1 md:grid-cols-2	gap-4 py-2.5">
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Nombre y Apellido
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nombre Completo"
                        onChange={(e) => setName(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Email
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Teléfono
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="number"
                        id="phone"
                        name="phone"
                        placeholder="Teléfono"
                        onChange={(e) => setPhone(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Dirección
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Dirección"
                        onChange={(e) => setAddress(e.target.value)}
                      ></input>
                    </div>
                    {/* New Inputs */}
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Provincia
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="provincia"
                        name="provincia"
                        placeholder="Provincia"
                        onChange={(e) => setProvincia(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Localidad
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="localidad"
                        name="localidad"
                        placeholder="Localidad"
                        onChange={(e) => setLocalidad(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        Código Postal
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="codigoPostal"
                        name="codigoPostal"
                        placeholder="Código Postal"
                        onChange={(e) => setCodigoPostal(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <label className="text-slate-800 text-base font-bold my-2">
                        DNI
                      </label>
                      <input
                        className="relative w-full cursor-default rounded-lg bg-slate-50 py-4 mr-2 px-6 text-left border border-slate-3000 text-slate-800 text-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-100 sm:text-sm"
                        type="text"
                        id="dni"
                        name="dni"
                        placeholder="DNI"
                        onChange={(e) => setDni(e.target.value)}
                      ></input>
                    </div>
                  </form>
                  <div className="my-3 w-full">
                    <button
                      onClick={submitForm}
                      className="secondary-background-color items-center italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center"
                    >
                      ENVIAR PEDIDO
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  );
};
