import { useEffect, useState } from "react";
import { useOrder } from "../../context/context";
import { FaShoppingCart, FaTrash } from "react-icons/fa";
import { Modal } from "../organisms/Modal"

export const SummaryCard = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { cart, addProductToCart, removeProductFromCart } = useOrder();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    // Check if any product in the cart has "vinilo" or "bordado" in its extras
    const hasBordadoOrVinilo = cart.some((product) => {
      return Object.keys(product.extras).some((key) =>
        key.includes("vinilo") || key.includes("bordado")
      );
    });

    // Check if the "Matriz de bordado" is already in the cart
    const matrizDeBordadoExists = cart.some(
      (product) => product.id === "matriz-de-bordado"
    );

    // Add "Matriz de bordado" if needed
    if (hasBordadoOrVinilo && !matrizDeBordadoExists) {
      addProductToCart({
        id: "matriz-de-bordado",
        name: "Matriz de bordado",
        quantity: 1,
        totalPrice: 10000,
        extras: {},
        size: "",
        notes: "",
        removeItem: false,
      });
    }

    // Remove "Matriz de bordado" if no product with "vinilo" or "bordado" is in the cart
    if (!hasBordadoOrVinilo && matrizDeBordadoExists) {
      removeProductFromCart("matriz-de-bordado");
    }
  }, [cart, addProductToCart, removeProductFromCart]);

  const calculateTotal = () => {
    return cart.reduce((total, product) => total + product.totalPrice, 0);
  };

  return (
    <div className="bg-white h-fit w-full md:w-[420px] rounded-md text-slate-800 shadow-sm">
      <div className="p-8 mb-4 secondary-background-color rounded-tl-md rounded-tr-md rounded-bl-none rounded-br-none text-white flex items-center justify-between">
        <p className="font-bold">Resumen de Compra</p>
      </div>

      <div className="cart-container">
        {cart.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-5 text-center">
            <FaShoppingCart className="text-gray-500 text-4xl mb-2" />
            <p className="text-gray-500">Aún no has agregado ningún producto a la lista.</p>
          </div>
        ) : (
          cart.map((product, index) => (
            <div key={index} className="flex justify-between items-center mb-4 px-4">
              <div>
                <p className="text-lg">{product.finalName}</p>
                <p>
                  Talle: {product.size} - {product.quantity} x ${product.totalPrice}
                </p>
              </div>
              {
                product.removeItem && (
                  <button
                    onClick={() => removeProductFromCart(product.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                )
              }
            </div>
          ))
        )}
      </div>
      <h4 className="font-bold text-lg px-4">Total: ${calculateTotal()}</h4>

      <button
        onClick={openModal}
        className="secondary-background-color italic uppercase py-4 px-6 rounded text-white text-sm font-bold w-full text-center mt-4"
      >
        ENVIAR PEDIDO
      </button>

      <Modal open={modalOpen} closeModal={closeModal} />
    </div>
  );
};
