import React, { useState } from 'react';
import { useOrder } from '../../context/context';

export const ProductMenu = ({ onSelectProduct }) => {
  const { products } = useOrder();
  const [selectedProduct, setSelectedProduct] = useState(null); 
  const [selectedFabric, setSelectedFabric] = useState(""); // Initialize with an empty string

  const orderedProducts = products.sort((a, b) => a.name.localeCompare(b.name));

  const handleProductChange = (event) => {
    const product = products.find(product => product.name === event.target.value);
    setSelectedProduct(product); 
    setSelectedFabric(""); // Reset fabric selection when product changes
  };

  const handleFabricChange = (event) => {
    const fabric = selectedProduct.fabrics.find(fabric => fabric.name === event.target.value);
    setSelectedFabric(fabric.name);
    onSelectProduct(fabric);
  };

  return (
    <div className="product-menu mb-5">
      <div className="d-flex flex-column">
        {/* Product dropdown */}
        <div className="mb-4">
          <label htmlFor="product-select" className="form-label">Selecciona un producto</label>
          <select
            id="product-select"
            value={selectedProduct ? selectedProduct.name : ""}
            onChange={handleProductChange}
            className='form-select'>
            <option value="" disabled>Selecciona un producto</option>
            {orderedProducts.map(product => (
              <option key={product.name} value={product.name}>
                {product.name}
              </option>
            ))}
          </select>
        </div>

        {/* Fabric dropdown - visible only when a product is selected */}
        {selectedProduct && (
          <div>
            <label htmlFor="fabric-select" className="form-label">Selecciona una tela</label>
            <select
              id="fabric-select"
              value={selectedFabric}
              onChange={handleFabricChange}
              className='form-select'>
              <option value="" disabled>Selecciona una tela</option>
              {selectedProduct.fabrics.map(fabric => (
                <option key={fabric.id} value={fabric.name}>
                  {fabric.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};
