import "./style.css";
import { Modal } from "../organisms/Modal"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { HomeBanner } from '../organisms/HomeBanner';
import { BusinessInfoSection } from "../organisms/BusinessInfoSection";
import { QuoteCalculator } from "../organisms/QuoteCalculatorSection";


export const Home = () => {
  return (
    <div className="wrapper flex flex-col w-full">
      <HomeBanner />

      <BusinessInfoSection />

      <QuoteCalculator />

      <Modal />

      <ToastContainer />
    </div>
  );
};
