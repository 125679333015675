import "./style.css";
import { CustomButton } from "../atoms/CustomButton";


export const HomeBanner = () => {


  return (
    <div className="homeBg w-full bg-no-repeat bg-cover flex-col flex md:flex-row py-44 md:py-44 px-11">
        <div className="w-full md:w-3/4 py-8">
          <h1 className="text-slate-900 break-words text-5xl md:text-7xl italic font-black my-5">
            Bienvenidos a <br className="d-none d-sm-block"/><span className="primary-color">BSPORT</span>{" "}
          </h1>
          <div className="rectangle primary-background-color my-5" />
          <br />
          <CustomButton
            className="my-5"
            text="COTIZA TU PEDIDO AHORA"
            type="button"
            path="#online-pricing"
          />
        </div>
        <div className="hidden md:w-1/4" />
      </div>
  );
};
