import "./style.css";
import { SubTitle } from "../atoms/SubTitle";

export const BusinessInfoSection = () => {
  return (
    <div className="py-4" id="quienes-somos">
      <div className="row text-slate-500 text-base">
        <div className="col-12 col-md-6 px-10">
          <SubTitle text="Nuestra historia" />
          <p className="my-3">
            Lucas Ignacio Bottazzi comenzó su experiencia desde muy chico en la empresa textil de su padre, realizando múltiples tareas, mientras realizaba la carrera de licenciatura en administración. En el último año de la carrera optó por comenzar a practicar las disciplinas de triatlón cuando detecto la necesidad que había en el mercado de personalizar la indumentaria deportiva de cada team, club o grupo de amigos. Fue todo una idea en tiempos de pandemia, Pero de a poco fue materializado este emprendimiento, con mil errores y aciertos. hoy en dia Lucas tiene su propio taller, y va creciendo en la industria textil. 
          </p>

          <p className="my-2">
            <b>Misión:</b> Brindar el mejor servicio y  darle un valor agregado a todos nuestros productos para una mejor experiencia de todos los clientes
          </p>

          <p className="my-2">
            <b>Visión:</b> Convertir a bsport en un proyecto que lo elijan instituciones como deportistas del mundo ofreciendo el mejor servicio y precio del mercado. 
          </p>
        </div>

        <div className="col-12 col-md-6 px-10">
          <SubTitle text="Servicios" />
          <p className="my-3">
            Nuestro equipo está capacitado para realizar el diseño digital que requiere el cliente, con su respectivo logo para cada team, club o evento, así también el planeamiento y ejecución para que el artículo se entregue en tiempo y forma. 
          </p>

          <ul className="list-disc pl-5">
            <li>Utilizamos telas, elásticos y cierres de muy buena calidad.</li>
            <li>Confección de alta calidad.</li>
            <li>Confiamos en nuestro trabajo por eso damos garantía de uso por nuestras prendas.</li>
            <li>Un packaging asegurado en todas las prendas para que tu pedido llegue en forma.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
